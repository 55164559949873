import { authRoles } from 'app/auth';
import SetPassword from './SetPassword';

const SetPasswordConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/password/reset/:token',
            component: SetPassword,
        },
    ],
};

export default SetPasswordConfig;
