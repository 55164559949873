import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TextToImage, DefaultParams } from "app/main/pages/models/TextToImage";
import { showMessage } from "./messageSlice";
import authorizedApi from "utils/authorizedApi";
import api from "utils/api";
import i18n from 'i18next';

export const setLikeImage = createAsyncThunk('imaiginationArt/global/setLikeImage', authorizedApi(async (data, { dispatch, rejectWithValue  }) => {
    try {
        const response = await api.post(`/generation/${data.generationId}/image/${data.imageId}/like`);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}));

export const sendFeedback = createAsyncThunk('imaiginationArt/global/sendFeedback', authorizedApi(async (data, { dispatch, rejectWithValue  }) => {
    try {
        const response = await api.post(`/user/feedback`, data);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}));

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        defaultModels: ['sd-1.5', 'midjourney', 'realistic-vision-v13'],
        supportedModels: [],
        supportedControlNets: [],
        prompt: '',
        negativePrompt: '',
        uploadedImage: null,
        imageWidth: DefaultParams.width,
        imageHeight: DefaultParams.height,
        scale: DefaultParams.guidance_scale,
        steps: DefaultParams.num_inference_steps,
        numbers: DefaultParams.samples,
        seed: DefaultParams.seed,
        selectedModel: '',
        selectedControlNet: '',
        likeAlert: {
            type: '',
            message: ''
        },
        feedbackAlert: {
            type: '',
            message: ''
        },
        openFeedback: false,
        showFeedbackIcon: false,
        generateCounter: localStorage.getItem('feedback_count')
    },
    reducers: {
        setSupportedModels: (state, action) => {
            const data = [...action.payload].sort((a, b) => a.search_priority - b.search_priority);
            state.supportedModels = data;
            state.selectedModel = data.find(obj => obj.default_model);
            state.defaultModels = data.slice(0, 3).map(({ model_id }) => model_id);
        },
        setSupportedControlNets: (state, action) => {
            state.supportedControlNets = action.payload;
        },
        setSelectedModel: (state, action) => {
            state.selectedModel = action.payload;
        },
        setSelectedControlNet: (state, action) => {
            state.selectedControlNet = action.payload;
        },
        setPrompt: (state, action) => {
            state.prompt = action.payload;
        },
        setNegativePrompt: (state, action) => {
            state.negativePrompt = action.payload;
        },
        setUploadedImage: (state, action) => {
            state.uploadedImage = action.payload;
        },
        setImageWidth: (state, action) => {
            state.imageWidth = action.payload;
        },
        setImageHeight: (state, action) => {
            state.imageHeight = action.payload;
        },
        setScale: (state, action) => {
            state.scale = action.payload;
        },
        setSteps: (state, action) => {
            state.steps = action.payload;
        },
        setNumbers: (state, action) => {
            state.numbers = action.payload;
        },
        setSeed: (state, action) => {
            state.seed = action.payload;
        },
        initLikeAlert: (state) => {
            state.likeAlert.type = '';
            state.likeAlert.message = '';
        },
        initFeedbackAlert: (state) => {
            state.feedbackAlert.type = '';
            state.feedbackAlert.message = '';
        },
        setGenerateCounter: (state, action) => {
            state.generateCounter = action.payload;
        },
        setOpenFeedback: (state, action) => {
            state.openFeedback = action.payload;
        },
        setFeedbackIcon: (state, action) => {
            state.showFeedbackIcon = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLikeImage.pending, (state) => {})
            .addCase(setLikeImage.fulfilled, (state, action) => {
                state.likeAlert.type = 'success';
                state.likeAlert.message = action.payload.data.message;
            })
            .addCase(setLikeImage.rejected, (state, action) => {
                state.likeAlert.type = 'error';
                if(action.payload !== undefined && action.payload.message !== undefined)
                    state.likeAlert.message = action.payload.message;
                else
                    state.likeAlert.message = i18n.t('errors.unkownError');
            })
            .addCase(sendFeedback.pending, (state) => {})
            .addCase(sendFeedback.fulfilled, (state, action) => {
                state.feedbackAlert.type = 'success';
                state.feedbackAlert.message = action.payload.data.message;
                const temp = state.generateCounter;
                if(temp >= process.env.REACT_APP_LIMITATION_FEEDBACK_COUNT) {
                    localStorage.setItem('feedback_count', 0);
                    state.generateCounter = 0;
                }
            })
            .addCase(sendFeedback.rejected, (state, action) => {
                state.feedbackAlert.type = 'error';
                if(action.payload !== undefined && action.payload.message !== undefined)
                    state.feedbackAlert.message = action.payload.message;
                else
                    state.feedbackAlert.message = i18n.t('errors.unkownError');

                const temp = state.generateCounter;
                if(temp >= process.env.REACT_APP_LIMITATION_FEEDBACK_COUNT) {
                    localStorage.setItem('feedback_count', 0);
                    state.generateCounter = 0;
                }
            })
    }
})

export const {
    setScale,
    setSteps,
    setNumbers,
    setSeed,
    setImageWidth,
    setImageHeight,
    setUploadedImage,
    setSelectedModel,
    setSelectedControlNet,
    setPrompt,
    setNegativePrompt,
    setSupportedModels,
    setSupportedControlNets,
    initLikeAlert,
    initFeedbackAlert,
    setGenerateCounter,
    setOpenFeedback,
    setFeedbackIcon
} = globalSlice.actions;

export default globalSlice.reducer;