import React from 'react';
import { Img } from '.';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { sendFeedback, initFeedbackAlert, setOpenFeedback } from 'app/store/fuse/globalSlice';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: '12px',
        top: '16px',
        color: theme.palette.grey[500],
    },
    title: {
        textAlign: 'center',
        fontSize: '20px'
    },
    question: {
        textAlign: 'left',
        fontSize: '14px'
    },
    description: {
        textAlign: 'center',
        fontSize: '14px',
        marginTop: '8px'
    }
}));

const Feedback = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();
    const [evaluation, setEvaluation] = useState(0);
    const [comment, setComment] = useState('');

    const user = useSelector(({ auth }) => auth.user);
    const feedbackAlert = useSelector(({ fuse }) => fuse.global.feedbackAlert);
    const generateCounter = useSelector(({ fuse }) => fuse.global.generateCounter);
    const openFeedback = useSelector(({ fuse }) => fuse.global.openFeedback);
    const showFeedbackIcon = useSelector(({ fuse }) => fuse.global.showFeedbackIcon);
    const feedbackCounts = [
        Number(process.env.REACT_APP_FIRST_FEEDBACK_COUNT),
        Number(process.env.REACT_APP_SECOND_FEEDBACK_COUNT),
        Number(process.env.REACT_APP_THIRD_FEEDBACK_COUNT)
    ];

    const openModal = (state) => {
        dispatch(setOpenFeedback(state));
    }
    const handleFeebackBtn = (value) => {
        setEvaluation(value);
    }
    
    const handleCommentChange = (event) => {
        setComment(event.currentTarget.value);
    }

    const leaveFeedback = () => {
        if(comment == '') {
            const notification = {
                message: t('alert.checkComment'),
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical  : 'top',
                    horizontal: 'center'
                },
                variant: 'error'
            }

            dispatch(showMessage(notification));
            return;
        }

        const data = {
            'email': user.data.email,
            'evaluation': evaluation,
            'comment': comment
        }

        dispatch(sendFeedback(data));
    }

    useEffect(() => {
        if(feedbackCounts.includes(generateCounter)) {
            dispatch(setOpenFeedback(true));
        }
    }, [generateCounter]);

    useEffect(() => {
        if(feedbackAlert.message !== '') {
            const notification = {
                message: feedbackAlert.message,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical  : 'top',
                    horizontal: 'center'
                },
                variant: feedbackAlert.type
            }

            dispatch(showMessage(notification));
            dispatch(initFeedbackAlert());
                
            if(feedbackAlert.type == 'success') {
                setComment('');
            }

            dispatch(setOpenFeedback(false));
        }
    }, [feedbackAlert])

    return (
        <>
            {
                showFeedbackIcon && openFeedback ?
                <div className="feedback-modal">
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => { openModal(false) }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className='mt-40'>
                        <h1 className={classes.title}>{ t('feedback.title') }</h1>
                        <p className={clsx(classes.description, 'default-gray-text-color')}>{ t('feedback.description') }</p>
                    </div>
                    <div className='mt-20'>
                        <h1 className={classes.question}>{ t('feedback.firstQuestion') }</h1>
                        <div className='face-btn-container'>
                            <Button
                                onClick={() => { handleFeebackBtn(-2) }}
                                className={evaluation == -2 ? 'btn-primary-art m-0' : 'face-btn m-0'}
                            >
                                <div className='fact-btn-content'>
                                    <Img
                                        src="assets/images/imaigination/icons/not_at_all.svg"
                                        className="w-40 h-40 mx-auto mb-4" alt="faceIcon"
                                    />
                                    <p>
                                        { t('feedback.notAtAll') }
                                    </p>
                                </div>
                            </Button>
                            <Button
                                onClick={() => { handleFeebackBtn(-1) }}
                                className={evaluation == -1 ? 'btn-primary-art m-0' : 'face-btn m-0'}
                            >
                                <div className='fact-btn-content'>
                                    <Img
                                        src="assets/images/imaigination/icons/bad.svg"
                                        className="w-40 h-40 mx-auto mb-4" alt="faceIcon"
                                    />
                                    <p>
                                        { t('feedback.bad') }
                                    </p>
                                </div>
                            </Button>
                            <Button
                                onClick={() => { handleFeebackBtn(0) }}
                                className={evaluation == 0 ? 'btn-primary-art m-0' : 'face-btn m-0'}
                            >
                                <div className='fact-btn-content'>
                                    <Img
                                        src="assets/images/imaigination/icons/ok.svg"
                                        className="w-40 h-40 mx-auto mb-4" alt="faceIcon"
                                    />
                                    <p>
                                        { t('feedback.ok') }
                                    </p>
                                </div>
                            </Button>
                            <Button
                                onClick={() => { handleFeebackBtn(1) }}
                                className={evaluation == 1 ? 'btn-primary-art m-0' : 'face-btn m-0'}
                            >
                                <div className='fact-btn-content'>
                                    <Img
                                        src="assets/images/imaigination/icons/good.svg"
                                        className="w-40 h-40 mx-auto mb-4" alt="faceIcon"
                                    />
                                    <p>
                                        { t('feedback.good') }
                                    </p>
                                </div>
                            </Button>
                            <Button
                                onClick={() => { handleFeebackBtn(2) }}
                                className={evaluation == 2 ? 'btn-primary-art m-0' : 'face-btn m-0'}
                            >
                                <div className='fact-btn-content'>
                                    <Img
                                        src="assets/images/imaigination/icons/great.svg"
                                        className="w-40 h-40 mx-auto mb-4" alt="faceIcon"
                                    />
                                    <p>
                                        { t('feedback.great') }
                                    </p>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div className='mt-20'>
                        <h1 className={classes.question}>{ t('feedback.secondQuestion') }</h1>
                        <TextareaAutosize 
                            value={comment}
                            onChange={ (event) => { handleCommentChange(event) }}
                            className='default-input-box w-full mt-12' 
                            aria-label="minimum height" 
                            minRows={3} 
                            maxLength={500} 
                            placeholder={ t('feedback.yourComment') } />
                    </div>
                    <div className='mt-24 text-center'>
                        <Button
                            variant="contained"
                            color="primary"
                            className='h-48 btn-secondary-art mx-auto w-60'
                            aria-label="LOG IN"
                            value="legacy"
                            onClick={() => { leaveFeedback() }}
                            >
                            { t('feedback.send') }
                        </Button>
                    </div>
                </div>
                :
                <></>
            }
            {
                showFeedbackIcon ?
                <div className="feedback-container">
                    <Button
                        onClick={ () => { openModal(!openFeedback) }}
                        className={'feedback-btn m-0'}
                    >
                        <Img
                            src="assets/images/imaigination/icons/ask.svg"
                            className="w-40 h-40"
                            alt="logo"
                        />
                    </Button>
                </div>
                :
                <></>
            }
        </>
    );
};

export default Feedback;