import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from '../../shared-components/Logo';
import MenuBar from '../../shared-components/MenuBar';
import Feedback from 'app/main/pages/components/Feedback';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import ResponsibleButton from 'app/fuse-layouts/shared-components/ResponsibleButton';
import ImaiginationButton from 'app/fuse-layouts/shared-components/ImaiginationButton';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

function ToolbarLayout1(props) {
    const toolbarTheme = useSelector(selectToolbarTheme);
    const user = useSelector(({ auth }) => auth.user);
    const classes = useStyles(props);
    const { i18n, t } = useTranslation();

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar
              id="fuse-toolbar"
              className={clsx(classes.root, 'flex relative z-20 shadow-md', props.className)}
              color="default"
              style={{ backgroundColor: 'var(--page-background-color)', justifyContent: 'space-between', borderBottom: '1px solid #3F416B' }}
              position="static"
            >
                <Toolbar className="p-0 min-h-48 md:min-h-64 h-70 justify-between">
                    <div className="flex px-16">
                        <Logo logoSize={'w-24 h-24'} logoText={'font-size-small'}/>
                    </div>
                    <MenuBar props={props}/>
                    <div className="flex items-center px-8 h-full overflow-x-auto">
                        {
                            (!user.role || (Array.isArray(user.role) && user.role.length === 0)) ?
                            <>
                                <ResponsibleButton
                                    component={Link}
                                    to={`/login`}
                                    className='btn-primary-art pt-8-pr-16 min-h-40 mr-8'
                                    src="assets/images/imaigination/icons/random-none.svg"
                                    text={ t('userMenu.login') }
                                />

                                <ResponsibleButton
                                    component={Link}
                                    to={`/register`}
                                    className='btn-secondary-art pt-8-pr-16 min-h-40'
                                    src="assets/images/imaigination/icons/search.svg"
                                    text={ t('userMenu.register') }
                                />
                            </>
                            :
                            <>
                                <UserMenu />
                                {/* <ImaiginationButton
                                    text="Upgrade account"
                                    className="btn-secondary-art"
                                /> */}
                            </>                            
                        }                        
                    </div>
                </Toolbar>
            </AppBar>
            <Feedback />
        </ThemeProvider>
    );
}

export default memo(ToolbarLayout1);
