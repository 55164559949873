import { lazy } from 'react';
import { authRoles } from 'app/auth';

const HistoryPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: '/history',
      auth: authRoles.user,
      exact: true,
      component: lazy(() => import('./HistoryPage')),
    },
  ],
};

export default HistoryPageConfig;
