import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Img } from 'app/main/pages/components';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

function SocialLinks(props) {
    const socialStyle = props.className;
    const elementStyle = props.elementStyle;

    const { i18n, t } = useTranslation();

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, scale: 0.6 },
        show: { opacity: 1, scale: 1 },
    };

    return (
        <motion.div variants={container} initial="hidden" animate="show" className={clsx('flex items-center', socialStyle)}>
            <Tooltip variants={item} title={ t('global.tw') } placement="bottom">
                <IconButton
                    className={clsx('px-4', elementStyle)}
                    variants={item}
                    rel="noreferrer noopener"
                    role="button"
                    onClick={props.twitter}
                >
                    <Img
                        src="assets/images/imaigination/img_twitter.svg"
                        className={clsx('w-24 h-24 md:h-auto object-cover rounded-[12px]')}
                        alt="rectangle1904"
                    />
                </IconButton>
            </Tooltip>
            <Tooltip variants={item} title={ t('global.fb') } placement="bottom">
                <IconButton
                    className={clsx('px-4', elementStyle)}
                    variants={item}
                    rel="noreferrer noopener"
                    role="button"
                    onClick={props.facebook}
                >
                    <Img
                        src="assets/images/imaigination/img_frame54.svg"
                        className={clsx('w-24 h-24 md:h-auto object-cover rounded-[12px]')}
                        alt="rectangle1904"
                    />
                </IconButton>
            </Tooltip>
            <Tooltip variants={item} title={ t('imageDialog.copyURL') } placement="bottom">
                <IconButton
                    className={clsx('px-4', elementStyle)}
                    variants={item}
                    rel="noreferrer noopener"
                    role="button"
                    onClick={props.copyURL}
                >
                    <Img
                        src="assets/images/imaigination/img_link.svg"
                        className={clsx('w-24 h-24 md:h-auto object-cover rounded-[12px]')}
                        alt="rectangle1904"
                    />
                </IconButton>
            </Tooltip>
        </motion.div>
    );
}

export default SocialLinks;