class TextToImage {
    constructor(
        controlnet_model,
        guidance_scale,
        height,
        init_image,
        model_id,
        negative_prompt,
        num_inference_steps,
        prompt,
        samples,
        seed,
        strength,
        width
    ) {
        this.controlnet_model = controlnet_model;
        this.guidance_scale = guidance_scale;
        this.height = height;
        this.init_image = init_image;
        this.model_id = model_id;
        this.negative_prompt = negative_prompt;
        this.num_inference_steps = num_inference_steps;
        this.prompt = prompt;
        this.samples = samples;
        this.seed = seed;
        this.strength = strength;
        this.width = width;
    }
}

class TextToImageForSDXL {
    constructor(
        api_name,
        prompt,
        negative_prompt,
        use_vae,
        width,
        height,
        init_image,
        samples,
        num_inference_steps,
        strength,
        guidance_scale,
        refiner_strength,
        seed
    ) {
        this.api_name = api_name;
        this.prompt = prompt;
        this.negative_prompt = negative_prompt;
        this.use_vae = use_vae;
        this.width = width;
        this.height = height;
        this.init_image = init_image;
        this.samples = samples;
        this.num_inference_steps = num_inference_steps;
        this.strength = strength;
        this.guidance_scale = guidance_scale;
        this.refiner_strength = refiner_strength;
        this.seed = seed
    }
}

class ResultImage {
    constructor(
        controlnet_model,
        guidance_scale,
        height,
        init_image,
        thumb_image,
        model_id,
        negative_prompt,
        num_inference_steps,
        prompt,
        samples,
        seed,
        strength,
        width
    ) {
        this.controlnet_model = controlnet_model;
        this.guidance_scale = guidance_scale;
        this.height = height;
        this.init_image = init_image;
        this.thumb_image = thumb_image;
        this.model_id = model_id;
        this.negative_prompt = negative_prompt;
        this.num_inference_steps = num_inference_steps;
        this.prompt = prompt;
        this.samples = samples;
        this.seed = seed;
        this.strength = strength;
        this.width = width;
    }
}

const DefaultParams  = {
    controlnet_model: '',
    max_guidance_scale: 20,
    guidance_scale: 7.5,
    min_guidance_scale: 0,
    minHeight: 256,
    height: 512,
    maxHeight: 768,
    init_image: '',
    model_id: '',
    negative_prompt: '',
    min_steps: 0,
    num_inference_steps: 31,
    max_steps: 50,
    prompt: '',
    min_samples: 1,
    samples: 1,
    max_samples: 4,
    min_seed: 0,
    seed: 0,
    max_seed: Math.pow(2, 56),
    strength: '',
    minWidth: 256,
    width: 512,
    maxWidth: 768
}

const AutoGrid = (totalImages, showedImages, image, imageIndex, startPosition, maxPosition, widthGrid, heightGrid) => {
    if(showedImages.find((item) => item.imageId == image.id))
        return { 
            currentGrid: 0,
            currentImage: null
        };

    if(image.prop.width > image.prop.height) {
        if(startPosition + widthGrid <= maxPosition) {
            return { 
                currentGrid: widthGrid,
                currentImage: image
            };
        } else {
            const foundImage = totalImages.slice(imageIndex).find(image => image.prop.width <= image.prop.height);
            return { 
                currentGrid: heightGrid,
                currentImage: foundImage ? foundImage : null
            };
        }
    }                                    
    else {
        return { 
            currentGrid: heightGrid,
            currentImage: image
        };
    }
}

const DimensionsOfSDXL = {
    width: [768, 832, 832, 896, 896, 960, 960, 1024, 1024, 1088, 1088, 1152, 1152, 1216, 1280],
    height: [1280, 1216, 1152, 1152, 1088, 1088, 1024, 1024, 960, 960, 896, 896, 832, 832, 768]
}

const BreakpointCols = {
    default: 5,
    1920: 5,
    1600: 4,
    1366: 4,
    1280: 3,
    960: 2,
    600: 2,
    480: 1,
}

export { TextToImage, TextToImageForSDXL, ResultImage, DimensionsOfSDXL, DefaultParams, BreakpointCols, AutoGrid };