import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .logo-icon': {
            transition: theme.transitions.create(['width', 'height'], {
              duration: theme.transitions.duration.shortest,
              easing: theme.transitions.easing.easeInOut,
            }),
        },
        '& .react-badge, & .logo-text': {
            transition: theme.transitions.create('opacity', {
              duration: theme.transitions.duration.shortest,
              easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    reactBadge: {
        backgroundColor: '#121212',
        color: '#61DAFB',
    },
}));

function Logo(props) {
    const { logoStyle, logoSize, logoText } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogoBtn = () => {
        history.push('/home');
        localStorage.setItem('redirectUrl', '/home');
    }

    return (
        <div className={clsx(classes.root, logoStyle, 'flex items-center')}>
            <img className={clsx(logoSize, "logo-icon")} src="assets/images/imaigination/logo/logo.svg" alt="logo" />
            <Button
                className={clsx(logoText)}
                onClick={handleLogoBtn}
            >
                Im<span className='logo-text'>ai</span>gination
            </Button>
        </div>
    );
}

export default Logo;
