import JwtService from "app/services/jwtService";
import { setUserData } from "app/auth/store/userSlice";
import { privateActions } from "./actionTypesWithAuth";

const authMiddleware = (store) => (next) => (action) => {
    // if (privateActions.includes(action.type)) {
    //     const access_token = window.localStorage.getItem('jwt_access_token');
    //     if(!JwtService.isAuthTokenValid()) {
    //         JwtService.signInWithToken()
    //             .then((user) => {
    //                 next(action);
    //             })
    //             .catch(error => {
    //                 next(action);
    //             });
    //     } else {
    //         // If the token is valid, execute next(action)
    //         // next(action);
    //     }
    // } else {
    //     next(action);
    // }
    next(action);
};

export default authMiddleware;