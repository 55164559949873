const publicActions = [
    'imaiginationArt/images/getImages/pending',
    'imaiginationArt/images/getImagesByQuery/pending',
    'imaiginationArt/images/getModels/pending',
    'imaiginationArt/images/getControlNets/pending',
    'imaiginationArt/generate/generateRandomPrompt/pending',
    'imaigination/prompt/getGenerationById/pending'
]

const privateActions = [
    'imaiginationArt/generate/previewGenerations/pending',
    'imaiginationArt/generate/upscaleImage/pending',
    'imaiginationArt/generate/deleteImage/pending',
    'imaiginationArt/generate/uploadImage/pending',
    'imaiginationArt/generate/getResultsById/pending',
    'imaiginationArt/generate/textToImage/pending',
    'imaiginationArt/generate/getImagefromURL/pending',
    'imaiginationArt/history/getHistoryImages/pending',
    'imaiginationArt/likes/getLikesImages/pending'
]

export { publicActions, privateActions };