import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import FixedBar from 'app/fuse-layouts/shared-components/FixedBar';
import SocialLinks from 'app/fuse-layouts/shared-components/SocialLinks';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: 'theme.palette.background.paper',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    gridStyle: {
        color: 'var(--footer-text-color)'
    },
    logoStyle: {
        padding: '10px 16px 10px 16px !important',
        justifyContent: 'center !important',
        fontSize: 'var(--sub-title-font-size) !important'
    },
    socialStyle: {
        marginTop: '12px',
        justifyContent: 'center !important'
    },
    logoContainer: {
        position: 'relative !important'
    },
    logoTextStyle: {
        backgroundColor: 'transparent !important',
    },
    footerMenuBtn: {
        color: 'var(--footer-text-color)',
        textDecoration: 'none !important'
    }
}));

function FooterLayout1(props) {
    const { i18n, t } = useTranslation();
    const footerTheme = useSelector(selectFooterTheme);
    const history = useHistory();
    const classes = useStyles();
    
    const handleFooter = (url) => {
        history.push(url);
        localStorage.setItem('redirectUrl', url);
    };

    return (
        <ThemeProvider theme={footerTheme}>
            <AppBar
              id="fuse-footer"
              className={clsx('relative z-20 shadow-md', props.className)}
              color="default"
              style={{ backgroundColor: 'var(--page-background-color)' }}
            >
                <Divider />
                <Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 items-center overflow-x-auto block">
                    <div className='mt-24 mb-24'>
                        <Logo logoSize={'w-40 h-40'} logoText={clsx('font-size-large', classes.logoTextStyle)} logoStyle={classes.logoStyle}/>
                        <SocialLinks className={classes.socialStyle} elementStyle={'mr-12 ml-12 w-28 h-28'}/>
                    </div>
                    <Grid container className='mb-12'>
                        <Grid item lg={2} md={2}></Grid>
                        <Grid item lg={8} md={8}>
                            <Grid container>
                                <Grid item lg={1} md={1}></Grid>
                                <Grid className={classes.gridStyle} item lg={2} md={2} sm={6} xs={12}>
                                    <Button
                                        // component={Link}
                                        onClick={() => handleFooter('/generate')}
                                        // to={'/generate'}
                                        className={clsx('w-full bc-transparent', classes.footerMenuBtn)}
                                        variant="contained">
                                        { t('global.generate') }
                                    </Button>
                                </Grid>
                                <Grid className={classes.gridStyle} item lg={2} md={2} sm={6} xs={12}>
                                    <Button
                                        // component={Link}
                                        // to={'/about-us'}
                                        onClick={() => { handleFooter('/about-us') }}
                                        className={clsx('w-full bc-transparent', classes.footerMenuBtn)}
                                        variant="contained">
                                        { t('footer.aboutUs') }
                                    </Button>
                                </Grid>
                                <Grid className={classes.gridStyle} item lg={2} md={2} sm={6} xs={12}>
                                    <Button
                                        onClick={() => { window.location.href = 'mailto:contact@imaigination.art'; }}
                                        className={clsx('w-full bc-transparent', classes.footerMenuBtn)}
                                        variant="contained">
                                        { t('footer.contactUs') }
                                    </Button>
                                </Grid>
                                <Grid className={classes.gridStyle} item lg={2} md={2} sm={6} xs={12}>
                                    <Button
                                        // component={Link}
                                        // to={'/privacy-policy'}
                                        onClick={() => { handleFooter('/privacy-policy') }}
                                        className={clsx('w-full bc-transparent', classes.footerMenuBtn)}
                                        variant="contained">
                                        { t('footer.privacyPolicy') }
                                    </Button>
                                </Grid>
                                <Grid className={classes.gridStyle} item lg={2} md={2} sm={6} xs={12}>
                                    <Button
                                        // component={Link}
                                        // to={'/terms-of-service'}
                                        onClick={() => { handleFooter('/terms-of-service') }}
                                        className={clsx('w-full bc-transparent', classes.footerMenuBtn)}
                                        variant="contained">
                                        { t('footer.termsOfService') }
                                    </Button>
                                </Grid>
                                <Grid item lg={1} md={1}></Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}></Grid>
                    </Grid>
                </Toolbar>
                <FixedBar />
              </AppBar>
        </ThemeProvider>
    );
}

export default memo(FooterLayout1);
