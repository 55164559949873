import React from 'react';
import { useState, useEffect } from 'react';
import { Img, Text } from '../pages/components';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { setNewPassword } from 'app/auth/store/loginSlice';
import { submitRegister } from 'app/auth/store/registerSlice';
import _ from '@lodash';
import clsx from 'clsx';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BigLogo from 'app/fuse-layouts/shared-components/BigLogo';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import jwtDecode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router';
import { showMessage } from 'app/store/fuse/messageSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    inputBox: {
        background: 'var(--default-background-color)',
        borderRadius: 'var(--default-border-radius)',
    },
    elementSize: {
        width: '60%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    defaultInputColor: {
        color: 'var(--white)',
        opacity: '0.4'
    },
    changeInputColor: {
        color: 'var(--white)',
        opacity: '1'
    }
}));

const schema = yup.object().shape({
    // displayName: yup.string().required('You must enter display name'),
    // email: yup.string().email(i18n.t('errors.emailValidation0')).required(i18n.t('errors.emailValidation1')),
    password: yup
        .string()
        .required(i18n.t('errors.passwordValidation0'))
        .min(8, i18n.t('errors.passwordValidation1')),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], i18n.t('errors.passwordValidation2')),
});

const defaultValues = {
    // displayName: '',
    email: '',
    password: '',
    passwordConfirm: '',
};

function SetPassword(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const setPasswordResult = useSelector(({ auth }) => auth.login.setPasswordResult);
    const { i18n, t } = useTranslation();
    const authRegister = useSelector(({ auth }) => auth.register);

    const { control, formState, handleSubmit, reset, setError, setValue } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });
      
    const { isValid, dirtyFields, errors } = formState;

    const [email, setEmail] =  useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');

    const handleInput = (event, type) => {
        switch(type) {
            case 'email':
                setEmail(event.target.value !== null ? event.target.value : '');
                break;
            case 'password':
                setPassword(event.target.value !== null ? event.target.value : '');
                break;
            case 'confirm':
                setConfirmPassword(event.target.value !== null ? event.target.value : '');
                break;
            default:
                setEmail(event.target.value !== null ? event.target.value : '');
                break;
        }        
    }

    const handleResetBtn = () => {
        const data = {
            'email': email,
            'password': password,
            'token': token
        }

        dispatch(setNewPassword(data));
    };

    useEffect(() => {
        authRegister.errors.forEach((error) => {
            setError(error.type, {
                type: 'manual',
                message: error.message,
            });
        });
    }, [authRegister.errors, setError]);

    useEffect(() => {
        const token = props.match.params.token;
        const params = new URLSearchParams(location.search);
        const email = params.get("email");

        setToken(token);
        setEmail(email); 
    });

    useEffect(() => {
        if(setPasswordResult.status !== undefined) {
            dispatch(
                showMessage({
                    message: setPasswordResult.msg,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical  : 'top',
                        horizontal: 'center'
                    },
                    variant: setPasswordResult.status
                })
            )

            if(setPasswordResult.status === 'success') {
                history.push('/home');
                localStorage.setItem('redirectUrl', '/home')
            }                
        }
    }, [setPasswordResult])

    function onSubmit(model) {
        dispatch(submitRegister(model));
    }

    return (
        <div className="flex flex-col flex-1 items-center justify-center p-16">
            <Grid className='first mb-120 mt-40' container spacing={3}>
                <Grid item lg={4} md={3} sm={2} xs={2}></Grid>
                <Grid item lg={4} md={6} sm={8} xs={8}>
                    <div className="max-w-465 text-center">
                        <div className="flex flex-col gap-3 items-center justify-start self-stretch w-auto md:w-full mb-24">
                            <BigLogo vertical={true} className="ml-10 login-logo-text" logoSize={'w-36 h-36'}/>
                            <div className="flex flex-col gap-3 items-center justify-start self-stretch w-auto">
                                <Text className="text-center fw-500 f-size-28 w-auto">
                                    { t('setNewPassword.title') }
                                </Text>
                                <Text
                                    className="not-italic text-center w-auto input-color"
                                    as="h3"
                                    variant="h3"
                                >
                                    { t('setNewPassword.description') }
                                </Text>
                            </div>
                        </div>
                        <div className='mb-24'>
                            <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className={clsx(classes.elementSize, 'mb-16 mx-auto')}
                                            type="text"
                                            error={!!errors.email}
                                            helperText={errors?.email?.message}
                                            placeholder={ t('global.emailAddress') }
                                            variant="outlined"
                                            required
                                            value={email}
                                            onInput={ (event) => { handleInput(event, 'email') } }
                                            InputProps={{
                                                className: clsx(classes.inputBox, 'h-48 sample-text-style input-color')
                                            }}
                                            inputProps={{
                                                className: email !== '' ? classes.changeInputColor : classes.defaultInputColor
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className={clsx(classes.elementSize, 'mb-16 mx-auto')}
                                            type="password"
                                            placeholder={ t('global.password') }
                                            error={!!errors.password}
                                            helperText={errors?.password?.message}
                                            variant="outlined"
                                            required
                                            onInput={ (event) => { handleInput(event, 'password') } }
                                            InputProps={{
                                                className: clsx(classes.inputBox, 'h-48 sample-text-style input-color')
                                            }}
                                            inputProps={{
                                                className: password !== '' ? classes.changeInputColor : classes.defaultInputColor
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="passwordConfirm"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className={clsx(classes.elementSize, 'mb-24 mx-auto')}
                                            type="password"
                                            placeholder={ t('login.confirmPassword') }
                                            error={!!errors.passwordConfirm}
                                            helperText={errors?.passwordConfirm?.message}
                                            variant="outlined"
                                            required
                                            onInput={ (event) => { handleInput(event, 'confirm') } }
                                            InputProps={{
                                                className: clsx(classes.inputBox, 'h-48 sample-text-style input-color'),
                                            }}
                                            inputProps={{
                                                className: confirmPassword !== '' ? classes.changeInputColor : classes.defaultInputColor
                                            }}
                                        />
                                    )}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    // className={!_.isEmpty(dirtyFields) && isValid ? 'btn-secondary-art w-full mx-auto mt-16' : 'btn-disable'}
                                    className={clsx(classes.elementSize, 'h-48 btn-secondary-art mx-auto')}
                                    aria-label="REGISTER"
                                    disabled={_.isEmpty(dirtyFields) || !isValid}
                                    value="legacy"
                                    onClick={ () => { handleResetBtn() }}
                                    >
                                    { t('setNewPassword.resetBtn') }
                                </Button>
                            </form>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={4} md={3} sm={2} xs={2}></Grid>
            </Grid>
        </div>
    );
}

export default SetPassword;
