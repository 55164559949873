import Error404PageConfig from './errors/404/Error404PageConfig';
import Error505PageConfig from './errors/505/Error505PageConfig';
import HomePageConfig from './home/HomePageConfig';
import GeneratePageConfig from './generate/GeneratePageConfig';
import PolicyPageConfig from './policy/PolicyPageConfig';
import UserSettingsPageConfig from './user-settings/UserSettingsPageConfig';
import PromptPageConfig from './prompt/PromptPageConfig';
import HistoryPageConfig from './history/HistoryPageConfig';
import LikesPageConfig from './likes/LikesPageConfig';
import PlansPageConfig from './plans/PlansPageConfig';
import AvatarPageConfig from './avatar/AvatarPageConfig';
import ContactPageConfig from './contact/ContactPageConfig';

const pagesConfigs = [
    Error404PageConfig,
    Error505PageConfig,
    HomePageConfig,
    GeneratePageConfig,
    PolicyPageConfig,
    UserSettingsPageConfig,
    PromptPageConfig,
    HistoryPageConfig,
    LikesPageConfig,
    PlansPageConfig,
    AvatarPageConfig,
    ContactPageConfig
];

export default pagesConfigs;
