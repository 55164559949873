import { lazy } from "react";

const PolicyPageConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {   
            path: [
                '/privacy-policy',
                '/terms-of-service'
            ],
            exact: true,
            component: lazy(() => import('./PolicyPage')),
        }
    ],
}

export default PolicyPageConfig;