import React from "react";

const variantClasses = {
    h1: "font-semibold md:text-2xl sm:text-[22px] text-[26px]",
    h2: "font-semibold text-xl",
    h3: "font-normal text-lg",
    h4: "text-base",
    h5: "text-[15px]",
    h6: "text-sm",
    body1: "font-normal text-xs",
};

const Text = ({ children, className, variant, as, ...restProps }) => {
    const Component = as || "span";
    return (
        <Component
            className={`${className} ${variant && variantClasses[variant]}`}
            {...restProps}
        >
            {children}
        </Component>
    );
};

export { Text };
