import React from "react";
import { Img, Text } from "app/main/pages/components";
import clsx from "clsx";

const BigLogo = (prop) => {
    const { className, vertical, logoSize } = prop;

    return (
        <>
            <div className={vertical == true ? "flex items-center mb-40" : "mb-40"}>
                <Img
                    src="assets/images/imaigination/logo/logo.svg"
                    className={clsx(logoSize, 'mx-auto')}
                    alt="logo"
                />
                <Text
                    className={clsx("text-center w-auto", className)}
                    as="h1"
                    variant="h1"
                >
                    Im<span className='logo-text'>ai</span>gination
                </Text>
            </div>
        </>        
    );
};

export default BigLogo;