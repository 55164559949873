import { lazy } from 'react';

const ContactPageConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: '/contact-us',
            exact: true,
            component: lazy(() => import('./ContactPage')),
        },
    ],
};

export default ContactPageConfig;
