import { lazy } from "react";
import { authRoles } from 'app/auth';

const PlansPageConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {   
            path: '/imai-payment-plans',
            exact: true,
            component: lazy(() => import('./PlansPage')),
        }
    ],
}

export default PlansPageConfig;