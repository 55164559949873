import React, { useEffect, useState } from 'react';
import { Text } from '../pages/components';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitLoginm, sendMail } from 'app/auth/store/loginSlice';
import clsx from 'clsx';
import _ from '@lodash';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ImaiginationButton from 'app/fuse-layouts/shared-components/ImaiginationButton';
import BigLogo from 'app/fuse-layouts/shared-components/BigLogo';
import i18n from 'i18next';
import { showMessage } from 'app/store/fuse/messageSlice';

const schema = yup.object().shape({
    email: yup.string().email(i18n.t('errors.emailValidation0')).required(i18n.t('errors.emailValidation1'))
});

const defaultValues = {
    email: ''
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    inputBox: {
        background: 'var(--default-background-color)',
        borderRadius: 'var(--default-border-radius)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    defaultInputColor: {
        color: 'var(--white)',
        opacity: '0.4'
    },
    changeInputColor: {
        color: 'var(--white)',
        opacity: '1'
    }
}));

function ResetPassword() {
    const classes = useStyles();
    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);
    const isMailSending = useSelector(({ auth }) => auth.login.isMailSending);
    const mailResult = useSelector(({ auth }) => auth.login.mailResult);
    const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const [email, setEmail] =  useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleInput = (event, type) => {
        switch(type) {
            case 'email':
                setEmail(event.target.value !== null ? event.target.value : '');
                break;
            case 'password':
                setPassword(event.target.value !== null ? event.target.value : '');
                break;
            default:
                setEmail(event.target.value !== null ? event.target.value : '');
                break;
        }        
    }

    const handleMailSender = () => {
        dispatch(sendMail({email}))
    }

    useEffect(() => {
        setValue('', '', { shouldDirty: false, shouldValidate: false });
    }, [reset, setValue, trigger]);

    useEffect(() => {
        login.errors.forEach((error) => {
            setError(error.type, {
                type: 'manual',
                message: error.message,
            });
        });
    }, [login.errors, setError]);

    useEffect(() => {
        if(mailResult.status !== undefined)
            dispatch(
                showMessage({
                    message: mailResult.msg,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical  : 'top',
                        horizontal: 'center'
                    },
                    variant: mailResult.status
                })
            )
    }, [mailResult])

    function onSubmit(model) {
        return;
    }

    return (
        <div className="flex flex-col flex-1 items-center justify-center p-16">
            <Grid className='first mb-120 mt-40' container spacing={3}>
                <Grid item lg={4} md={3} sm={2} xs={2}></Grid>
                <Grid item lg={4} md={6} sm={8} xs={8}>
                    <div className="max-w-465 text-center">
                        <div className="flex flex-col gap-3 items-center justify-start self-stretch w-auto md:w-full mb-24">
                            <BigLogo vertical={true} className="ml-10 login-logo-text" logoSize={'w-36 h-36'}/>
                            <div className="flex flex-col gap-3 items-center justify-start self-stretch w-auto">
                                <Text className="text-center mb-8 fw-500 f-size-20 w-auto">
                                    { t('resetPassword.title') }
                                </Text>
                                <p className="not-italic text-center w-auto input-color">
                                    { t('resetPassword.description') }
                                </p>
                            </div>
                        </div>
                        <div className='mb-24'>
                            <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className='mb-16 mx-auto w-60'
                                            type="text"
                                            error={!!errors.email}
                                            helperText={errors?.email?.message}
                                            placeholder={ t('global.emailAddress') }
                                            variant="outlined"
                                            required
                                            onInput={ (event) => { handleInput(event, 'email') } }
                                            InputProps={{
                                                className: clsx(classes.inputBox, 'h-48 sample-text-style')
                                            }}
                                            inputProps={{
                                                className: email !== '' ? classes.changeInputColor : classes.defaultInputColor
                                            }}
                                        />
                                    )}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className='h-48 btn-secondary-art mx-auto w-60'
                                    aria-label="LOG IN"
                                    disabled={_.isEmpty(dirtyFields) || !isValid || isMailSending}
                                    onClick={() => { handleMailSender() }}
                                    value="legacy"
                                    >
                                    {
                                        mailResult.status === 'success' ?
                                        t('resetPassword.sendMailAgain')
                                        :
                                        t('resetPassword.sendMail')
                                    }
                                </Button>
                            </form>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={4} md={6} sm={2} xs={2}></Grid>
            </Grid>
        </div>
    );
}

export default ResetPassword;
