import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Img } from 'app/main/pages/components';
import { logoutUser } from 'app/auth/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenFeedback, setFeedbackIcon } from 'app/store/fuse/globalSlice';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function UserMenu(props) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const [userMenu, setUserMenu] = useState(null);
    const [displayName, setDisplayName] = useState('');
    const openFeedback = useSelector(({ fuse }) => fuse.global.openFeedback);

    const userMenuClick = (event) => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    const handleMenu = (url) => {
        history.push(url);
        setUserMenu(null);
        localStorage.setItem('redirectUrl', url);
    }

    const handleFeedback = (state) => {
        dispatch(setOpenFeedback(state));
        setUserMenu(null);
    }

    useEffect(() => {
        const fullName = user.data.displayName;
        const words = fullName.split(" ");
        let initials = "";
        words.map(word => {
            initials += word.charAt(0);
        })

        setDisplayName(initials);
    }, [user]);

    return (
        <>
            <Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6 user-avatar-container" onClick={userMenuClick}>
                {/* <div className="hidden md:flex flex-col mx-4 items-end">
                    <Typography component="span" className="font-semibold flex">
                        {user.data.displayName}
                    </Typography>
                    <Typography className="text-11 font-medium capitalize" color="textSecondary">
                        Credits: {user.data.credits !== undefined ? user.data.credits : 0}
                    </Typography>
                </div> */}

                {/* {user.data.photoURL ? (
                    <Avatar className="md:mx-4" alt="user photo" src={user.data.photoURL} />
                ) : (
                    <Avatar className="md:mx-4 user-avatar-container" variant='rounded'>U</Avatar>
                )} */}
                <h3>{ displayName !== "" ? displayName : 'U' }</h3>
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: 55,
                    horizontal: 55,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'py-8 user-menu',
                }}
            >
                <>
                    <MenuItem
                        onClick={() => { handleMenu('/history') }}
                        role="button"
                    >
                        <ListItemText primary={ t('userMenu.myGallery') } />
                    </MenuItem>
                    <MenuItem
                        onClick={() => { handleMenu('/likes') }}
                        role="button"
                    >
                        <ListItemText primary={ t('userMenu.myFavorites') } />
                    </MenuItem>
                    <MenuItem
                        onClick={() => { handleFeedback(!openFeedback) }}
                        role="button"
                    >
                        <ListItemText primary={ t('userMenu.feedback') } />
                    </MenuItem>
                    <MenuItem
                        onClick={() => { handleMenu('/settings') }}
                        role="button"
                    >
                        <ListItemText primary={ t('userMenu.settings') } />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={() => {
                            dispatch(logoutUser());
                            dispatch(setFeedbackIcon(false));
                            dispatch(setOpenFeedback(false));
                            userMenuClose();
                            localStorage.setItem('redirectUrl', '/home');
                        }}
                    >
                        <ListItemText primary={ t('userMenu.logout') } />
                        <div>
                            <Img
                                src="assets/images/imaigination/icons/logout.svg"
                                className="w-24 h-24"
                                alt="logo"
                            />
                        </div>
                    </MenuItem>
                </>
            </Popover>
        </>
    );
}

export default UserMenu;
