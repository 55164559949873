import FuseUtils from '@fuse/utils';
import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import ResetPasswordConfig from 'app/main/reset-password/ResetPasswordConfig';
import SetPasswordConfig from 'app/main/set-password/SetPasswordConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import RegisterConfig from 'app/main/register/RegisterConfig';
import { Redirect } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { useHistory } from 'react-router';

const routeConfigs = [
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  LogoutConfig,
  ResetPasswordConfig,
  SetPasswordConfig,
  LoginConfig,
  RegisterConfig,
  CallbackConfig,
];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: '/',
    exact: true,
    component: () => {
      const history = useHistory();
      const searchParam = new URLSearchParams(window.location.search).get('search');
      
      if(searchParam) {
        return (
          <Redirect to={`/home?search=${encodeURIComponent(searchParam)}`} />
        );
      } else {
        return (
          <Redirect to="/home" />
        );
      }   
    },
  },
  {
    path: '/loading',
    exact: true,
    component: () => <FuseLoading />,
  },
  {
    component: () => <Redirect to="/errors/404" />,
  },
];

export default routes;
