import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation, withTranslation } from 'react-i18next';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '0 !important',
        height: '100%'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    menuIcon: {
        cursor: 'pointer',
    },
  
    listItem: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: 'var(--footer-text-color)',
        borderBottom: 'var(--header-border-bottom)',
        textAlign: 'center',
        '&:hover': {
            color: 'var(--white)',
            backgroundColor: 'var(--default-background-color)',
        },
    },

    selectedListItem: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: 'var(--white)',
        backgroundColor: 'transparent !important',
        textAlign: 'center',
    },
    '@media (min-width: 960px)': {
        selectedListItem: {
            borderBottom: 'var(--header-border-selected-bottom)',
        },
    },
}));

const LabelBottomNavigation = (props) => {
    const { i18n, t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const currentMenu = useSelector(({ fuse }) => fuse.global.currentMenu);

    const handleChange = (event, newValue) => {
        switch(newValue) {
            case "/home":
                history.push(`/home`);
                localStorage.setItem('redirectUrl', '/home');
                break;
            case "/generate":
                history.push(`/generate`);
                localStorage.setItem('redirectUrl', '/generate');
                break;
            case "/history":
                history.push(`/history`);
                localStorage.setItem('redirectUrl', '/history');
                break;
            case "/likes":
                history.push(`/likes`);
                localStorage.setItem('redirectUrl', '/likes');
                break;
            case "/plans":
                history.push(`/imai-payment-plans`);
                localStorage.setItem('redirectUrl', '/imai-payment-plans');
                break;
            case "/account":
                history.push(`/settings`);
                localStorage.setItem('redirectUrl', '/settings');
                break;
            case "/contact-us":
                history.push(`/contact-us`);
                localStorage.setItem('redirectUrl', '/contact-us');
                break;
            default:
                history.push(`/home`);
                localStorage.setItem('redirectUrl', '/home');
                break;
        }
    };

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        const redirectUrl = localStorage.getItem('redirectUrl');
        switch(redirectUrl) {
            case '/imai-payment-plans':
                setValue('/plans');
                break;
            case '/settings':
                setValue('/account');
                break;
            default:
                setValue(redirectUrl);
                break;
        }
    }, [location]);

    // useEffect(() => {
    //     const handleBeforeUnload = () => {
    //         localStorage.setItem('redirectUrl', '/home');
    //     };
    
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    return (
        <>
            <Hidden smDown>
                <List component="nav" aria-label="main navigation" className={classes.root}>
                    <ListItem
                        button
                        selected={value === '/home'}
                        onClick={() => handleChange(null, '/home')}
                        className={value === '/home' ? classes.selectedListItem : classes.listItem}>
                        <ListItemText primary={ t('menuBar.home') } />
                    </ListItem>
                    <ListItem
                        button
                        selected={value === '/generate'}
                        onClick={() => handleChange(null, '/generate')}
                        className={value === '/generate' ? classes.selectedListItem : classes.listItem}>
                        <ListItemText primary={ t('menuBar.generate') } />
                    </ListItem>
                    <ListItem
                        button
                        selected={value === '/history'}
                        onClick={() => handleChange(null, '/history')}
                        className={value === '/history' ? classes.selectedListItem : classes.listItem}>
                        <ListItemText primary={ t('menuBar.history') } />
                    </ListItem>
                    <ListItem
                        button
                        selected={value === '/likes'}
                        onClick={() => handleChange(null, '/likes')}
                        className={value === '/likes' ? classes.selectedListItem : classes.listItem}>
                        <ListItemText primary={ t('menuBar.likes') } />
                    </ListItem>
                    {/* <ListItem
                        button
                        selected={value === '/plans'}
                        onClick={() => handleChange(null, '/plans')}
                        className={value === '/plans' ? classes.selectedListItem : classes.listItem}>
                        <ListItemText primary="Plans" />
                    </ListItem> */}
                    <ListItem
                        button
                        selected={value === '/account'}
                        onClick={() => handleChange(null, '/account')}
                        className={value === '/account' ? classes.selectedListItem : classes.listItem}>
                        <ListItemText primary={ t('menuBar.account') } />
                    </ListItem>
                </List>
            </Hidden>
            <Hidden mdUp>
                <MenuIcon className={classes.menuIcon} onClick={handleMenuClick} />
                <Drawer anchor="top" open={menuOpen} onClose={handleMenuClick}>
                    <List component="nav" aria-label="main navigation">
                        <ListItem
                            button
                            selected={value === '/home'}
                            onClick={() => {
                              handleChange(null, '/home');
                              handleMenuClick();
                            }}
                            className={value === '/home' ? classes.selectedListItem : classes.listItem}>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem
                            button
                            selected={value === '/generate'}
                            onClick={() => {
                              handleChange(null, '/generate');
                              handleMenuClick();
                            }}
                            className={value === '/generate' ? classes.selectedListItem : classes.listItem}>
                            <ListItemText primary="Generate" />
                        </ListItem>
                        <ListItem
                            button
                            selected={value === '/history'}
                            onClick={() => {
                              handleChange(null, '/history');
                              handleMenuClick();
                            }}
                            className={value === '/history' ? classes.selectedListItem : classes.listItem}>
                            <ListItemText primary="History" />
                        </ListItem>
                        <ListItem
                            button
                            selected={value === '/likes'}
                            onClick={() => {
                              handleChange(null, '/likes');
                              handleMenuClick();
                            }}
                            className={value === '/likes' ? classes.selectedListItem : classes.listItem}>
                            <ListItemText primary="Likes" />
                        </ListItem>
                        <ListItem
                            button
                            selected={value === '/plans'}
                            onClick={() => {
                              handleChange(null, '/plans');
                              handleMenuClick();
                            }}
                            className={value === '/plans' ? classes.selectedListItem : classes.listItem}>
                            <ListItemText primary="Plans" />
                        </ListItem>
                        <ListItem
                            button
                            selected={value === '/account'}
                            onClick={() => {
                              handleChange(null, '/account');
                              handleMenuClick();
                            }}
                            className={value === '/account' ? classes.selectedListItem : classes.listItem}>
                            <ListItemText primary="Account" />
                        </ListItem>
                    </List>
                </Drawer>
            </Hidden>
        </>
    );
}

export default withTranslation()(LabelBottomNavigation);