import { lazy } from 'react';

const PromptPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: '/prompt/:generationId',
      exact: true,
      component: lazy(() => import('./PromptPage')),
    },
  ],
};

export default PromptPageConfig;
