import React from "react";
import { Button } from "@material-ui/core";
import { Img } from "app/main/pages/components";

const ResponsibleButton = (props) => {
    const { component, to, className, src, text } = props;

    return (
        <Button
            component={component}
            to={to}
            className={className}
        >
            <Img
                src={src}
                className='responsible-icon'
            />
            <span className="responsible-text">{text}</span>
        </Button>
    );
};

export default ResponsibleButton;